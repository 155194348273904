import React from 'react';
import { useSelector } from 'react-redux';
import getIsGlobalLoading from '@src/containers/Session/selectors/getIsGlobalLoading';
import Preloader from '..';

const PreloaderCDefault = () => {
	const isActive = useSelector(getIsGlobalLoading);
	return <Preloader isActive={isActive} />;
};

export default PreloaderCDefault;
