import Cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX, MAX_VIEWED } from '../constants';
import getCookieParams from '../selectors/getCookieParams';
import getViewed from '../selectors/getViewed';

const pureState = {
	viewed: {
		spaces: [], // { id: number }
	},
};

const tooggleViewedItem = createAsyncThunk(
	`${ACTIONS_PREFIX}/toggleViewedItem`,
	async ({ item, type, isRemove = false }, { getState }) => {
		const params = getCookieParams(getState());
		const currentItems = getViewed(getState());
		let newItems;

		if (isRemove) {
			newItems = {
				...currentItems,
				[type]: currentItems[type].filter(({ id }) => id !== item.id),
			};
		} else if (currentItems[type].some(({ id }) => id === item.id)) {
			newItems = {
				...currentItems,
				[type]: [item].concat(
					currentItems[type]
						.filter(({ id }) => id !== item.id)
						.slice(0, MAX_VIEWED),
				),
			};
		} else {
			newItems = {
				...currentItems,
				[type]: [item].concat(currentItems[type]).slice(0, MAX_VIEWED),
			};
		}

		Cookies.set('viewed', JSON.stringify(newItems), {
			...params,
			expires: 364,
		});

		return { viewed: newItems };
	},
);

const reducerFactory = (builder) => {
	builder.addCase(tooggleViewedItem.fulfilled, (state, action) => {
		const { viewed } = action.payload;
		return {
			...state,
			viewed,
		};
	});
};

export default {
	reducerFactory,
	action: tooggleViewedItem,
	pureState,
};
