import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ACTIONS_PREFIX } from '../constants';
import fetchSitemap from './fetchSitemap';

export const pureState = {
	...fetchSitemap.pureState,
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			return {
				...state,
				fetchSitemap: action.payload.Sitemap.fetchSitemap,
			};
		});
		fetchSitemap.reducerFactory(builder);
	},
});

export const actions = {
	...set.actions,
};

export const { reducer } = set;
