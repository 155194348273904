import { createAsyncThunk } from '@reduxjs/toolkit';
import getRouterCtx from '@src/containers/Session/selectors/getRouterCtx';

import { ACTIONS_PREFIX } from '../constants';

const getCityByDomain = createAsyncThunk(
	`${ACTIONS_PREFIX}/getCityByDomain`,
	async (customParams = {}, { extra, getState }) => {
		const { params } = getRouterCtx(getState());
		const { town: domain } = params;
		const { api } = extra;
		const response = await api.apiv2.get('cities', {
			params: {
				domain,
				embed: ['country'],
				fields: ['name3'],
				...customParams,
			},
		});

		return {
			cityData: response.data?.cities[0] || null,
		};
	},
);

const reducerFactory = (builder) => {
	builder.addCase(getCityByDomain.fulfilled, (state, action) => {
		const { cityData } = action.payload;
		return {
			...state,
			cityFromUrl: {
				...cityData,
			},
		};
	});

	builder.addCase(getCityByDomain.rejected, (state) => {
		return {
			...state,
			cityFromUrl: null,
		};
	});
};

export default {
	reducerFactory,
	action: getCityByDomain,
};
