import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';
import { ACTIONS_PREFIX } from '../constants';
import { getFormerSuggestionByKey } from '../selectors/getFormerSuggestions';

const pureState = {
	formerSuggestions: {},
};

const saveSuggestion = createAsyncThunk(
	`${ACTIONS_PREFIX}/saveSuggestion`,
	async ({ dbKey, suggestion }, { getState }) => {
		const formerSuggestions =
			getFormerSuggestionByKey(dbKey)(getState()) || [];

		const isDuplicated = formerSuggestions.some(
			(item) => item.id === suggestion.id,
		);

		const newSuggestions = isDuplicated
			? formerSuggestions
			: formerSuggestions.concat(suggestion);

		localStorage.setItem(dbKey, JSON.stringify({ items: newSuggestions }));

		return newSuggestions;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(saveSuggestion.pending, (state, { meta }) => {
		const { dbKey } = meta.arg;

		return {
			...state,
			formerSuggestions: {
				...state.formerSuggestions,
				[dbKey]: {
					...state.formerSuggestions[dbKey],
					status: ASYNC_STATUSES.PENDING,
				},
			},
		};
	});

	builder.addCase(saveSuggestion.fulfilled, (state, { meta, payload }) => {
		const { dbKey } = meta.arg;

		return {
			...state,
			formerSuggestions: {
				...state.formerSuggestions,
				[dbKey]: {
					data: payload,
					status: ASYNC_STATUSES.RESOLVED,
					error: null,
				},
			},
		};
	});

	builder.addCase(saveSuggestion.rejected, (state, { meta, error }) => {
		const { dbKey } = meta.arg;

		return {
			...state,
			formerSuggestions: {
				...state.formerSuggestions,
				[dbKey]: {
					data: null,
					status: ASYNC_STATUSES.REJECTED,
					error: error.message,
				},
			},
		};
	});
};

export default {
	reducerFactory,
	action: saveSuggestion,
	pureState,
};
