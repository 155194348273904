import Cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTIONS_PREFIX } from '../constants';
import getCookieParams from '../selectors/getCookieParams';
import getViewedPoints from '../selectors/getViewedPoints';

const pureState = {
	viewedPoints: [],
};

const toggleViewedPoint = createAsyncThunk(
	`${ACTIONS_PREFIX}/toggleViewedPoint`,
	async ({ id, isRemove = false }, { getState }) => {
		const params = getCookieParams(getState());
		const currentViewed = getViewedPoints(getState());
		const newViewed = currentViewed.filter((item) => id !== item.id);

		if (!isRemove) {
			newViewed.push(id);
		}

		Cookies.set('viewedPoints', JSON.stringify(newViewed), {
			...params,
		});

		return { viewedPoints: newViewed };
	},
);

const reducerFactory = (builder) => {
	builder.addCase(toggleViewedPoint.fulfilled, (state, action) => {
		const { viewedPoints } = action.payload;
		return {
			...state,
			viewedPoints,
		};
	});
};

export default {
	reducerFactory,
	action: toggleViewedPoint,
	pureState,
};
