import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	fetchRecentViewed: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const fetchRecentViewed = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchRecentViewed`,
	async ({ params }, { extra }) => {
		const { api } = extra;
		const { ids, ...restParams } = params;
		const formedIds = Array.isArray(ids) ? ids : [ids];
		const response = await api.apiv3.get(
			`loftcard/${formedIds.filter((i) => !!i).join(',')}`,
			{ params: restParams },
		);

		return response.data;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchRecentViewed.pending, (state) => {
		return {
			...state,
			fetchRecentViewed: {
				...state.fetchRecentViewed,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(fetchRecentViewed.fulfilled, (state, action) => {
		return {
			...state,
			fetchRecentViewed: {
				...state.fetchRecentViewed,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(fetchRecentViewed.rejected, (state, action) => {
		const { meta } = action;
		return {
			...state,
			fetchRecentViewed: {
				...state.fetchRecentViewed,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { ...action.error, arg: meta.arg },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: fetchRecentViewed,
};
