import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';
import { loadSFUParams } from '../selectors/fetchSFUParams';

const pureState = {
	fetchSFU: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};
const fetchSFU = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchSFU`,
	async (_, { extra, getState }) => {
		const { api } = extra;
		const loadParams = loadSFUParams(getState());
		const response = await api.apiv2.get('directory/filters', {
			params: loadParams,
		});

		return response.data;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchSFU.pending, (state) => {
		return {
			...state,
			fetchSFU: {
				...state.fetchSFU,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(fetchSFU.fulfilled, (state, action) => {
		return {
			...state,
			fetchSFU: {
				...state.fetchSFU,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(fetchSFU.rejected, (state, action) => {
		const { meta, error } = action;
		return {
			...state,
			fetchSFU: {
				...state.fetchSFU,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { message: error.message || '', arg: meta.arg || {} },
			},
		};
	});
};

export default {
	action: fetchSFU,
	pureState,
	reducerFactory,
};
