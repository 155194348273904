import React from 'react';
import isDeepEqual from 'fast-deep-equal/react';

const useDeepUpdate = (eff, deps) => {
	const storage = React.useRef(null);

	const isChanged = (d) => {
		return d.some((val, i) => {
			return !isDeepEqual(val, storage.current[i]);
		});
	};

	React.useEffect(() => {
		if (storage.current === null || !isChanged(deps)) {
			storage.current = deps;
			return () => {};
		}

		storage.current = deps;
		return eff();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};

export default useDeepUpdate;
