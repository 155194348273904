import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';
import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	loadProfile: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const loadProfile = createAsyncThunk(
	`${ACTIONS_PREFIX}/loadProfile`,
	async ({ params, space_id }, { extra }) => {
		const { api } = extra;
		const response = await api.apiv3.get(`loftpage/${space_id}`, {
			params,
		});

		const entity = response.data.entity[space_id];

		if (!entity) return null;

		const { review, media } = entity;
		const mediaIds = media.filter((m) => Number.isInteger(m)).join(',');
		const reviewIdsForRequest = review.filter((r) => !r.id);

		const responsesPromise = reviewIdsForRequest.map((id) =>
			api.apiv3.get(`/venuereview/${id}`, { params }),
		);

		let imagesData = {};

		if (mediaIds) {
			const medias = await api.apigo.get(`img/${mediaIds}`);
			imagesData = medias?.data?.entity;
		}

		const responses = await Promise.allSettled(responsesPromise);

		const loadedReviews = responses
			.map((res, i) => {
				if (res.status === 'fulfilled') {
					return res.value.data.entity[reviewIdsForRequest[i]];
				}

				return res;
			})
			.filter((r) => r.id);

		const serverEntity = response.data.entity[space_id];

		return {
			...response.data,
			entity: {
				...response.data.entity,
				[space_id]: {
					...serverEntity,
					review: review.filter((r) => r.id).concat(loadedReviews),
					media: serverEntity.media.map((m) => {
						if (Number.isInteger(m)) {
							return imagesData[m] || m;
						}
						return m;
					}),
				},
			},
		};
	},
);

const reducerFactory = (builder) => {
	builder.addCase(loadProfile.pending, (state) => {
		return {
			...state,
			loadProfile: {
				...state.loadProfile,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(loadProfile.fulfilled, (state, action) => {
		return {
			...state,
			loadProfile: {
				...state.loadProfile,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(loadProfile.rejected, (state, action) => {
		const { meta } = action;

		return {
			...state,
			loadProfile: {
				...state.loadProfile,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { ...action.error, arg: meta.arg },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: loadProfile,
};
