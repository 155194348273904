import { combineReducers } from '@reduxjs/toolkit';
import { reducer as sessionReducer } from '@src/containers/Session/reducers';
import { reducer as spacesListingReducer } from '@src/containers/SpacesListing/reducers';
import { reducer as spacesFavouriteReducer } from '@src/containers/SpacesFavourite/reducers';
import { reducer as cacheReducer } from '@src/containers/Cache/reducers';
import { reducer as filtersReducer } from '@src/containers/Filters/reducers';
import { reducer as placeProfileReducer } from '@src/containers/PlaceProfile/reducers';
import { reducer as spaceProfileReducer } from '@src/containers/SpaceProfile/reducers';
import { reducer as homepageReducer } from '@src/containers/Homepage/reducers';
import { reducer as mapReducer } from '@src/containers/Map/reducers';
import { reducer as mediaReducer } from '@src/containers/Media/reducers';
import { reducer as sitemapReducer } from '@src/containers/Sitemap/reducers';

const rootReducer = combineReducers({
	Cache: cacheReducer,
	Filters: filtersReducer,
	PlaceProfile: placeProfileReducer,
	SpaceProfile: spaceProfileReducer,
	Session: sessionReducer,
	SpacesListing: spacesListingReducer,
	SpacesFavourite: spacesFavouriteReducer,
	Homepage: homepageReducer,
	Map: mapReducer,
	Media: mediaReducer,
	Sitemap: sitemapReducer,
});

export default rootReducer;
