export const ACTIONS_PREFIX = 'Session';
export const GOOGLE_API_KEY = 'AIzaSyA_Beq89MLCnFZNCCZMWb_hqcCOZcZ01KU';
export const YANDEX_API_KEY = '915af580-a1c5-465c-8f4b-4cbccfacf2ef';

export const LANG_IDS = {
	en: 3,
	ru: 1,
};

export const CONTACTS_WRAP_ID = '__spotlist__contacts_wrap';

export const MODAL_IDS = {
	FILTER_MODAL: 'filterModal',
	TAG_FILTERS: 'tagFilters',
	CONTACTS_MODAL: 'contactsModal',
	SPACE_DESCRIPTION: 'spaceDescription',
	SPACE_STAFF: 'spaceStaff',
	SPACE_RULES: 'spaceRules',
	SPACE_REVIEWS: 'spaceReviews',
	MAP: 'map',
	MEDIA: 'media',
	CLAIM: 'claim',
	CHOICE: 'choice',
	QUESTION: 'question',
	CLAIM_CONFIRM: 'claim_confirm',
	CLAIM_DENY: 'claim_deny',
	PLACE_MAP: 'place_map',
};

export const ROUTE_PATHS = {
	MAIN: '/',
	LISTING: '/[town]/',
	SPACE: '/[town]/spaces/[space_id]/',
	PLACE: '/[town]/places/[place_id]/',
	SPACES_FAVOUR: '/[town]/favorites/',
};

export const FAVOURITE_TYPES = {
	SPACE: 'spaces',
};

export const VIEWED_TYPES = {
	SPACE: 'spaces',
};

export const MAX_VIEWED = 10;

export const DEFAULT_CITY = {
	id: 4400,
	region_id: 4312,
	domain: 'msk',
	is_show_region: false,
	name: 'Москва',
	name3: 'Москва',
	is_agglomeration: 1,
	agglomeration_name: '',
	timezone: 'Europe/Moscow',
	user_count: 65535,
	is_can_request: 2,
	country: {
		id: 3159,
		name: 'Russia',
		phone_code: '+7',
		iso: 'RU',
		currency_id: 5,
		billing_currency_id: 5,
	},
};

export const LEAD_TYPES = {
	booking: 'BOOKING',
	question: 'CALLBACK',
	claim: 'COMPILATION',
};
