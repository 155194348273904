import { createSelector } from 'reselect';

export const getQuery = (store) => store.Session.query;
export const getParams = (store) => store.Session.params;
const getResolvedUrl = (store) => store.Session.resolvedUrl;

const getRouterCtx = createSelector(
	getQuery,
	getParams,
	getResolvedUrl,
	(query, params, resolvedUrl) => {
		return {
			query,
			params,
			resolvedUrl,
		};
	},
);

export default getRouterCtx;
