import { createAsyncThunk } from '@reduxjs/toolkit';
import toggleViewedPoint from '@src/containers/Session/reducers/toggleViewedPoint';
import { ACTIONS_PREFIX } from '../constants';

const initialState = {
	activeId: null,
};

const setActiveMarkerId = createAsyncThunk(
	`${ACTIONS_PREFIX}/setActiveMarkerId`,
	async (payload, { dispatch }) => {
		if (payload.id !== -1) {
			dispatch(toggleViewedPoint.action({ id: payload.id }));
		}
		return payload;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(setActiveMarkerId.fulfilled, (state, action) => {
		const { id } = action.payload;

		return {
			...state,
			activeId: id,
		};
	});
};

export default {
	action: setActiveMarkerId,
	initialState,
	reducerFactory,
};
