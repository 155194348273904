import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ACTIONS_PREFIX } from '../constants';
import loadProfile from './loadProfile';
import loadStrapiProfile from './loadStrapiProfile';
import fetchRatesList from './fetchRatesList';

export const pureState = {
	...loadProfile.pureState,
	...loadStrapiProfile.pureState,
	...fetchRatesList.pureState,
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			return {
				...state,
				loadProfile: action.payload.SpaceProfile.loadProfile,
				loadStrapiProfile:
					action.payload.SpaceProfile.loadStrapiProfile,
			};
		});

		loadProfile.reducerFactory(builder);
		loadStrapiProfile.reducerFactory(builder);
		fetchRatesList.reducerFactory(builder);
	},
});

export const actions = {
	...set.actions,
	loadProfile: loadProfile.action,
};

export const { reducer } = set;
