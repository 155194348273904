import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	loadFilters: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const loadFilters = createAsyncThunk(
	`${ACTIONS_PREFIX}/loadFilters`,
	async ({ params }, { extra }) => {
		const { api } = extra;
		const response = await api.apiv3.get('loftfilter', { params });

		return response.data;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(loadFilters.pending, (state) => {
		return {
			...state,
			loadFilters: {
				...state.loadFilters,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(loadFilters.fulfilled, (state, action) => {
		return {
			...state,
			loadFilters: {
				...state.loadFilters,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(loadFilters.rejected, (state, action) => {
		const { meta } = action;
		return {
			...state,
			loadFilters: {
				...state.loadFilters,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { ...action.error, arg: meta.arg },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: loadFilters,
};
