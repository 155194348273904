import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';
import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	loadStrapiProfile: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const loadStrapiProfile = createAsyncThunk(
	`${ACTIONS_PREFIX}/loadStrapiProfile`,
	async ({ gorkoId }, { extra }) => {
		const { api } = extra;

		const r = await api.strapi.get(`places/${gorkoId}/profile`);

		return { ...r.data };
	},
);

const reducerFactory = (builder) => {
	builder.addCase(loadStrapiProfile.pending, (state) => {
		return {
			...state,
			loadStrapiProfile: {
				...state.loadStrapiProfile,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(loadStrapiProfile.fulfilled, (state, action) => {
		return {
			...state,
			loadStrapiProfile: {
				...state.loadStrapiProfile,
				status: ASYNC_STATUSES.RESOLVED,
				error: null,
				data: action.payload,
			},
		};
	});

	builder.addCase(loadStrapiProfile.rejected, (state, action) => {
		const { meta } = action;
		return {
			...state,
			loadStrapiProfile: {
				...state.loadStrapiProfile,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { arg: meta.arg },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: loadStrapiProfile,
};
