import { createSlice } from '@reduxjs/toolkit';
import fetchSpacesPoints from './fetchSpacesPoints';
import setActiveMarkerId from './setActiveMarkerId';
import { ACTIONS_PREFIX } from '../constants';

export const pureState = {
	...fetchSpacesPoints.initialState,
	...setActiveMarkerId.initialState,
	isMapLayerOpen: false,
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
		toggleMapLayer: (state, action) => {
			const { isOpen, coords } = action.payload || {};
			const isMapLayerOpen =
				isOpen === undefined ? !state.isMapLayerOpen : isOpen;
			return {
				...state,
				isMapLayerOpen,
				initCoords: coords === undefined ? state.initCoords : coords,
			};
		},
		setInitCoords: (state, action) => {
			const { coords } = action.payload;

			return {
				...state,
				initCoords: coords,
			};
		},
	},
	extraReducers: (builder) => {
		fetchSpacesPoints.reducerFactory(builder);
		setActiveMarkerId.reducerFactory(builder);
	},
});

export const actions = {
	...set.actions,
	fetchSpacesPoints: fetchSpacesPoints.action,
	setActiveMarkerId: setActiveMarkerId.action,
};

export const { reducer } = set;
