import Cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTIONS_PREFIX } from '../constants';
import getCookieParams from '../selectors/getCookieParams';
import getFavourites from '../selectors/getFavourites';

const pureState = {
	favourites: {
		spaces: [], // { id: number }
	},
};

const tooggleFavourItem = createAsyncThunk(
	`${ACTIONS_PREFIX}/toggleFavouriteItem`,
	async ({ item, type, isRemove = false }, { getState }) => {
		const params = getCookieParams(getState());
		const currentFavs = getFavourites(getState());
		let newFavs;

		if (isRemove) {
			newFavs = {
				...currentFavs,
				[type]: currentFavs[type].filter(({ id }) => id !== item.id),
			};
		} else {
			newFavs = {
				...currentFavs,
				[type]: currentFavs[type].concat([item]),
			};
		}

		Cookies.set('favourites', JSON.stringify(newFavs), {
			...params,
			expires: 364,
		});

		return { favourites: newFavs };
	},
);

const reducerFactory = (builder) => {
	builder.addCase(tooggleFavourItem.fulfilled, (state, action) => {
		const { favourites } = action.payload;
		return {
			...state,
			favourites,
		};
	});
};

export default {
	reducerFactory,
	action: tooggleFavourItem,
	pureState,
};
