import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import css from './style.module.scss';

const Preloader = ({ isActive, isAbsolute }) => {
	if (!isActive) return null;

	return (
		<div className={cn(css.wrap, { [css.wrap_absolute]: isAbsolute })}>
			<img
				className={css.img}
				src="/img/loaders/global.png"
				alt={t('Загрузка...')}
				width={128}
				height={128}
			/>
		</div>
	);
};

Preloader.propTypes = {
	isActive: PropTypes.bool,
	isAbsolute: PropTypes.bool,
};

Preloader.defaultProps = {
	isActive: false,
	isAbsolute: false,
};

export default Preloader;
