import { createSelector } from 'reselect';
import getCityFromIP from './getCityFromIP';
import getCityFromUrl from './getCityFromUrl';
import getCitySelected from './getCitySelected';
import { DEFAULT_CITY } from '../constants';

export const getCity = createSelector(
	getCityFromUrl,
	getCityFromIP,
	getCitySelected,
	(domainCity, ipCity, selectedCity) => {
		return domainCity || selectedCity || ipCity || DEFAULT_CITY;
	},
);
