import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ACTIONS_PREFIX } from '../constants';
import loadFilters from './loadFilters';
import fetchSFU from './fetchSFU';
import fetchStrapiSFU from './fetchStrapiSFU';

export const pureState = {
	capacityValues: [],
	filterValues: {},
	sfuValues: {},
	isSFU: false,
	isMainFiltersVisible: true,
	...fetchSFU.pureState,
	...fetchStrapiSFU.pureState,
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
		setCapacityValues: (state, action) => {
			const { capacityValues } = action.payload;
			return {
				...state,
				capacityValues,
			};
		},

		setSFUValues: (state, action) => {
			return {
				...state,
				sfuValues: action.payload.values,
				isSFU: action.payload.isSFU,
			};
		},

		setFilterValues: (state, action) => {
			const { payload } = action;

			return {
				...state,
				filterValues: {
					...state.filterValues,
					...payload.values,
				},
			};
		},

		setMainFiltersVisible: (state, action) => {
			const { payload } = action;

			return {
				...state,
				isMainFiltersVisible: payload,
			};
		},
	},

	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			return {
				...state,
				loadFilters: action.payload.Filters.loadFilters,
				capacityValues: action.payload.Filters.capacityValues,
				filterValues: action.payload.Filters.filterValues,
				sfuValues: action.payload.Filters.sfuValues,
				isSFU: action.payload.Filters.isSFU,
				isMainFiltersVisible: pureState.isMainFiltersVisible,
				fetchSFU: action.payload.Filters.fetchSFU,
				fetchStrapiSFU: action.payload.Filters.fetchStrapiSFU,
			};
		});
		loadFilters.reducerFactory(builder);
		fetchSFU.reducerFactory(builder);
		fetchStrapiSFU.reducerFactory(builder);
	},
});

export const actions = {
	...set.actions,
};

export const { reducer } = set;
