import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';

export const pureState = {
	ipToCity: {
		data: null,
		status: ASYNC_STATUSES.IDLE,
		error: null,
	},
};

const getIPToCity = createAsyncThunk(
	`${ACTIONS_PREFIX}/getIPToCity`,
	async (_, { extra }) => {
		try {
			const { api } = extra;
			const response = await api.apiv2.get('tools/iptocity');

			return {
				data: response.data,
				status: ASYNC_STATUSES.RESOLVED,
				error: null,
			};
		} catch (error) {
			return {
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: error.message,
			};
		}
	},
);

const reducerFactory = (builder) => {
	builder.addCase(getIPToCity.pending, (state) => {
		return {
			...state,
			ipToCity: {
				...state.ipToCity,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(getIPToCity.fulfilled, (state, action) => {
		return {
			...state,
			ipToCity: {
				...state.ipToCity,
				status: action.payload.status,
				data: action.payload.data,
				error: action.payload.error,
			},
		};
	});

	builder.addCase(getIPToCity.rejected, (state, action) => {
		return {
			...state,
			ipToCity: {
				...state.ipToCity,
				data: null,
				status: action.payload.status,
				error: action.payload.error,
			},
		};
	});
};

export default {
	reducerFactory,
	action: getIPToCity,
};
