import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { ACTIONS_PREFIX } from '../constants';
import getCookieParams from '../selectors/getCookieParams';

const pureState = {
	selectedCity: null,
};

const setSelectedCity = createAsyncThunk(
	`${ACTIONS_PREFIX}/setSelectedCity`,
	async (payload, { getState }) => {
		if (typeof window !== 'undefined') {
			const cookieParams = getCookieParams(getState());

			if (payload?.city?.id) {
				Cookies.set('userCity', JSON.stringify(payload.city), {
					...cookieParams,
					expires: 364,
				});
			}
		}
		return payload;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(setSelectedCity.fulfilled, (state, action) => {
		const { city } = action.payload;

		return {
			...state,
			selectedCity: city,
		};
	});
};

export default {
	reducerFactory,
	action: setSelectedCity,
	pureState,
};
