import axios from 'axios';
import qs from 'qs';
import * as AxiosLogger from 'axios-logger';
import { addTimingHeader } from '@src/libs/utils/addTimingHeader';

export const apiFactory = (params = {}) => {
	const { userIp, isWithLogger = false, ctx, hosts } = params;

	const headers = {};
	const api = {};

	let apiHost = hosts?.api || 'https://spotlyst.ru';

	let strapiHost = hosts?.strapi || 'https://strapi.spotlyst.ru';

	if (typeof window !== 'undefined') {
		// eslint-disable-next-line no-underscore-dangle
		const state = window.__NEXT_DATA__.props?.pageProps?.initialState;
		const { hosts: sessionHosts } = state.Session || {};

		if (sessionHosts) {
			apiHost = sessionHosts.api || apiHost;
			strapiHost = sessionHosts.strapi || strapiHost;
		}
	}

	if (userIp) {
		headers['x-real-ip'] = userIp;
	}

	const commonProps = {
		headers,
		validateStatus: (status) => {
			return status >= 200 && status < 300;
		},
		paramsSerializer: (p) => {
			const result = qs.stringify(p, { arrayFormat: 'comma' });
			return result;
		},
	};

	const apiv2 = axios.create({
		...commonProps,
		baseURL: `${apiHost}/api/v2/`,
	});

	const apiv3 = axios.create({
		...commonProps,
		baseURL: `${apiHost}/api/v3/`,
	});

	const apigo = axios.create({
		...commonProps,
		headers: {},
		baseURL: `${apiHost}/api/go/`,
	});

	const apiNest = axios.create({
		...commonProps,
		headers: {},
		baseURL: `${apiHost}/api/nest/`,
	});

	const apiPlain = axios.create({
		...commonProps,
		headers: {},
		baseURL: `${apiHost}/`,
	});

	const apiStrapi = axios.create({
		...commonProps,
		headers: {},
		baseURL: `${strapiHost}/api/`,
	});

	if (isWithLogger) {
		[apiv2, apiv3, apigo, apiNest, apiStrapi].forEach((it) => {
			it.interceptors.request.use((request) => {
				const config = AxiosLogger.requestLogger(request, {
					params: true,
				});
				if (request.method === 'get') {
					const query = request.paramsSerializer(request.params);
					const url = `${request.baseURL}${request.url}${
						query ? `?${query}` : ''
					}`;
					// eslint-disable-next-line no-underscore-dangle
					api.__store.dispatch({
						type: 'REQUEST_LOG',
						payload: { url },
					});

					config.measure = {
						url: request.url,
						initTime: performance.now(),
					};
				}

				return config;
			});

			it.interceptors.response.use((response) => {
				const url = response.config.measure?.url;
				const start = response.config.measure?.initTime;
				const duration = performance.now() - start;

				addTimingHeader(ctx, 'request', duration, url);

				return response;
			});
		});
	}

	api.apiv2 = apiv2;
	api.apiv3 = apiv3;
	api.apigo = apigo;
	api.apiNest = apiNest;
	api.apiPlain = apiPlain;
	api.strapi = apiStrapi;

	return api;
};

export const api = apiFactory();

export const { apiv2, apigo, apiv3 } = api;
