import { createWrapper } from 'next-redux-wrapper';
import { configureStore } from '@reduxjs/toolkit';
import { apiFactory, api as defaultApi } from '@src/libs/utils/fetcher/axios';
import getUserIpFromRequest from '@src/libs/utils/getUserIpFromRequest';
import rootReducer from '@src/store/reducers';
import { addTimingHeader } from '@src/libs/utils/addTimingHeader';

const makeStore =
	({ api = defaultApi } = {}) =>
	() => {
		const store = configureStore({
			reducer: rootReducer,
			middleware: (getDefaultMiddleware) => {
				const middlewares = getDefaultMiddleware();
				const thunkIndex = middlewares.findIndex((i) => {
					return !!i.withExtraArgument;
				});

				middlewares[thunkIndex] = middlewares[
					thunkIndex
				].withExtraArgument({ api });

				return middlewares;
			},
		});

		// eslint-disable-next-line no-underscore-dangle, no-param-reassign
		api.__store = store;

		return store;
	};

export const wrapperFactory = (params) =>
	createWrapper(makeStore(params), {
		debug: false && process.env.NODE_ENV === 'development',
	});

const wrapCbWithMeasure = (cb, method, ctx) => {
	return async (...args) => {
		const start = performance.now();
		const result = await cb(...args);
		const duration = performance.now() - start;

		addTimingHeader(ctx, 'ssr_actions', duration);

		return result;
	};
};
const connectStore = (method) => (cb) => {
	return async (ctx) => {
		const isDebug = !!ctx.req?.cookies?.soul;
		const userIp = ctx.req ? getUserIpFromRequest(ctx.req) : null;
		const api = apiFactory({
			userIp,
			isWithLogger: isDebug,
			ctx,
			hosts: {
				api: process.env.API_HOST,
				strapi: process.env.STRAPI_HOST,
			},
		});
		const measurableCb = (store) =>
			wrapCbWithMeasure(cb(store, ctx.res), method, ctx);
		return wrapperFactory({ api })[method](measurableCb)(ctx);
	};
};

export const connectServerSideProps = connectStore('getServerSideProps');
export const connectStaticProps = connectStore('getStaticProps');
export const connectInitialProps = connectStore('getInitialPageProps');

const wrapper = wrapperFactory();

export default wrapper;
