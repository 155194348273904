import React from 'react';
import { getScrollbarWidth } from './utils/getScrollbarWidth';

const stylerId = '--app-styler';
const headerId = 'app_header';

const CheckDimensions = () => {
	const checkDimensions = React.useCallback(() => {
		const scrollWidth = getScrollbarWidth();
		const headerElem = document.getElementById(headerId);
		const windowHeight = window.innerHeight;
		const headerHeight = headerElem ? headerElem.offsetHeight : null;

		const style = `
			.--window-height {
				${windowHeight ? `height: ${windowHeight}px;` : ''}
			}
			.--window-min-height {
				${windowHeight ? `min-height: ${windowHeight}px;` : ''}
			}
			${
				!headerHeight
					? ''
					: `
						.--header-height {
							${headerHeight ? `height: ${headerHeight}px;` : ''}
						}
						.--header-min-height {
							${headerHeight ? `min-height: ${headerHeight}px;` : ''}
						}
					`
			}
			${
				!scrollWidth
					? ''
					: `

						.--scroll-compensator {
							padding-right: ${scrollWidth}px;
						}
						.--scroll-compensator .--scroll-fix-compensator {
							padding-right: ${scrollWidth}px;
						}
					`
			}
			:root, * {
				--scroll-bar-width: ${scrollWidth || 0}px;
			}
		`;

		let styleTag = document.getElementById(stylerId);

		if (!styleTag) {
			styleTag = document.createElement('style');
			styleTag.id = stylerId;
			document.head.append(styleTag);
		}

		styleTag.innerHTML = style;
	}, []);

	React.useEffect(() => {
		checkDimensions();
		window.addEventListener('resize', checkDimensions);
		window.addEventListener('updateDimensions', checkDimensions);
		return () => {
			window.removeEventListener('resize', checkDimensions);
			window.addEventListener('updateDimensions', checkDimensions);
		};
	}, [checkDimensions]);

	return null;
};

export default CheckDimensions;
