export const ACTIONS_PREFIX = 'Homepage';

export const HOMEPAGE_H1 = t('Поиск мест для ваших мероприятий');
export const EVENTS_TITLE = t('Найдется место для любого мероприятия');
export const EVENTS_DESCRIPTION = t(
	'На Spotlyst большое количество различных мест для проведения самых разных мероприятий. Начните свой поиск с выбора типа мероприятия.',
);
export const POPULAR_TITLE = t('Популярные места');
export const POPULAR_DESCRIPTION = t(
	'Найдите идеальное место для вашего мероприятия за считанные минуты среди специально подобранных площадок.',
);
