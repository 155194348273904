import qs from 'qs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';
import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	fetchRatesList: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const fetchRatesList = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchRatesList`,
	async ({ gorkoId, ...rest }, { extra }) => {
		const { api } = extra;
		const query = qs.stringify({ ...rest }, { addQueryPrefix: true });
		const r = await api.strapi.get(`spaces/${gorkoId}/rates${query}`);

		const target = r.data;

		if (!target) return null;

		return {
			...target,
		};
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchRatesList.pending, (state) => {
		return {
			...state,
			fetchRatesList: {
				...state.fetchRatesList,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(fetchRatesList.fulfilled, (state, action) => {
		return {
			...state,
			fetchRatesList: {
				...state.fetchRatesList,
				status: ASYNC_STATUSES.RESOLVED,
				error: null,
				data: action.payload,
			},
		};
	});

	builder.addCase(fetchRatesList.rejected, (state, action) => {
		const { meta } = action;
		return {
			...state,
			fetchRatesList: {
				...state.fetchRatesList,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { arg: meta.arg },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: fetchRatesList,
};
