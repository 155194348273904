/* eslint-disable no-param-reassign */
import qs from 'qs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';
import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	loadStrapiProfile: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const addMediaFromAlbums = (data) => {
	try {
		if (!data?.space) return;

		if (!data.space.plans?.shape?.imageBindings) {
			data.space.plans.shape.imageBindings = {};
		}
		if (!data.space.plans?.shape?.albumBindings) {
			data.space.plans.shape.albumBindings = {};
		}

		const combinedMedia = data.space.media || [];

		const imageBindings = data.space.plans?.shape?.imageBindings;
		const albumBindings = data.space.plans?.shape?.albumBindings;

		if (data.space?.albums?.length > 0) {
			data.space.albums.forEach((album) => {
				if (album.medias?.length) {
					album.medias.forEach((m) => {
						const bindings = albumBindings?.[`${album.id}`];

						if (bindings) {
							imageBindings[`${m.id}`] = [
								...(imageBindings[`${m.id}`] || []),
								...bindings,
							];
						}

						combinedMedia.push({
							...m,
							caption: m.caption ? m.caption : album.title,
						});
					});
				}
			});
		}

		data.space.media = combinedMedia.length > 0 ? combinedMedia : null;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
	}
};

const loadStrapiProfile = createAsyncThunk(
	`${ACTIONS_PREFIX}/loadStrapiProfile`,
	async ({ gorkoId }, { extra }) => {
		const { api } = extra;
		const query = qs.stringify({ rateLimit: 4 }, { addQueryPrefix: true });
		const r = await api.strapi.get(`spaces/${gorkoId}/profile${query}`);

		const target = r.data;

		if (!target) return null;

		addMediaFromAlbums(target);

		return {
			...target,
		};
	},
);

const reducerFactory = (builder) => {
	builder.addCase(loadStrapiProfile.pending, (state) => {
		return {
			...state,
			loadStrapiProfile: {
				...state.loadStrapiProfile,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(loadStrapiProfile.fulfilled, (state, action) => {
		return {
			...state,
			loadStrapiProfile: {
				...state.loadStrapiProfile,
				status: ASYNC_STATUSES.RESOLVED,
				error: null,
				data: action.payload,
			},
		};
	});

	builder.addCase(loadStrapiProfile.rejected, (state, action) => {
		const { meta } = action;
		return {
			...state,
			loadStrapiProfile: {
				...state.loadStrapiProfile,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { arg: meta.arg },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: loadStrapiProfile,
};
