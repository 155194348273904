import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	fetchPlaceProfile: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const fetchPlaceProfile = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchPlaceProfile`,
	async ({ params }, { extra }) => {
		const { api } = extra;
		const { id, ...restProfile } = params.profile;
		const response = await api.apiv3.get(`venue/${id}`, {
			params: { ...restProfile },
		});
		const profile = response.data.entity[`${id}`];
		const roomsResponse = await api.apiv3.get(`loftcard`, {
			params: params.rooms,
		});
		return { profile, rooms: roomsResponse.data };
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchPlaceProfile.pending, (state) => {
		return {
			...state,
			fetchPlaceProfile: {
				...state.fetchPlaceProfile,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(fetchPlaceProfile.fulfilled, (state, action) => {
		return {
			...state,
			fetchPlaceProfile: {
				...state.fetchPlaceProfile,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(fetchPlaceProfile.rejected, (state, action) => {
		const { meta } = action;
		return {
			...state,
			fetchPlaceProfile: {
				...state.fetchPlaceProfile,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { ...action.error, arg: meta.arg },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: fetchPlaceProfile,
};
