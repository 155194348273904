import { HYDRATE } from 'next-redux-wrapper';
import { createSlice } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX, ORDER_VARIANTS } from '../constants';
import loadSpaces from './loadSpaces';
import fetchRecentViewed from './fetchRecentViewed';

export const pureState = {
	listOrder: ORDER_VARIANTS.PRICE_REVERSE,
	...loadSpaces.pureState,
	...fetchRecentViewed.pureState,
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
		setListOrder: (state, action) => {
			const { order } = action.payload || {};
			return {
				...state,
				listOrder: order || ORDER_VARIANTS.PRICE_REVERSE,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			const { SpacesListing } = action.payload;

			return {
				...state,
				loadSpaces: SpacesListing.loadSpaces,
				fetchRecentViewed: SpacesListing.fetchRecentViewed,
			};
		});
		loadSpaces.reducerFactory(builder);
		fetchRecentViewed.reducerFactory(builder);
	},
});

export const actions = {
	...set.actions,
	loadSpaces: loadSpaces.action,
	fetchRecentViewed: fetchRecentViewed.action,
};

export const { reducer } = set;
