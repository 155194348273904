/* eslint-disable react/no-danger */
import React from 'react';
import Language from 'gorko.translator/build/language';
import '@src/styles/global.scss';
import Head from 'next/head';

import { wrapperFactory } from '@src/store';
import useRouterWatcher from '@src/containers/Session/hooks/useRouterWatcher';
import useClientSession from '@src/containers/Session/hooks/useClientSession';
import PreloaderCDefault from '@src/components/Preloader/connectors/default';
import CheckDimensions from '@src/components/CheckDimensions';

// TODO: add localization flow
Language.set('ru');

// eslint-disable-next-line react/prop-types
const MyApp = ({ Component, pageProps }) => {
	useRouterWatcher();
	useClientSession();

	// React.useEffect(() => {
	// 	import('modernizr').then(() => {});
	// }, []);

	return (
		<>
			<CheckDimensions />
			<PreloaderCDefault />
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
				/>
			</Head>
			<Component {...pageProps} />
		</>
	);
};

export default wrapperFactory().withRedux(MyApp);
