import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import {
	ACTIONS_PREFIX,
	EVENTS_DESCRIPTION,
	EVENTS_TITLE,
	HOMEPAGE_H1,
	POPULAR_DESCRIPTION,
	POPULAR_TITLE,
} from '../constants';

export const pureState = {
	h1: HOMEPAGE_H1,
	eventsTitle: EVENTS_TITLE,
	eventsDescription: EVENTS_DESCRIPTION,
	popularTitle: POPULAR_TITLE,
	popularDescription: POPULAR_DESCRIPTION,
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			return {
				...state,
				...action.payload.Homepage,
			};
		});
	},
});

export const actions = {
	...set.actions,
};

export const { reducer } = set;
