import qs from 'qs';
import { createSelector } from 'reselect';
import getFiltersQuery from '@src/containers/Filters/selectors/getFiltersQuery';
import getLangId from './getLangId';
import { getCity } from './getCity';

const getQuery = (store) => store.Session.query;

const getFetchTitleParams = createSelector(
	getLangId,
	getCity,
	getFiltersQuery,
	getQuery,
	(langId, city, filters, query) => {
		const queryParams = { ...filters, ...query };
		const queryString = qs.stringify(queryParams, {
			arrayFormat: 'comma',
			encode: false,
		});
		return {
			cityId: city.id,
			languageId: langId,
			queryString: encodeURIComponent(queryString),
		};
	},
);

export default getFetchTitleParams;
