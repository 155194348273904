import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';
import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	fetchSitemap: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const fetchSitemap = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchSitemap`,
	async (_, { extra }) => {
		const { api } = extra;
		const response = await api.apiPlain.get(`sitemap.json`);
		const { directory, venue } = response.data;
		return { directory, venue };
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchSitemap.pending, (state) => {
		return {
			...state,
			fetchSitemap: {
				...state.fetchSitemap,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(fetchSitemap.fulfilled, (state, action) => {
		return {
			...state,
			fetchSitemap: {
				...state.fetchSitemap,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: fetchSitemap,
};
