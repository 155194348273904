export const ACTIONS_PREFIX = 'filters';
export { SUGGESTION_IMGS } from './images';

export const FILTER_CODES = {
	MAIN: 'param_main',
	PAYMENT: 'param_payment',
	SPEC: 'spec',
	CAPACITY: 'capacity',
	DATE: 'date',
	TYPE: 'type',
	PRICE: 'price',
	PARAM_MAIN: 'param_main',
	PARAM_PAYMENT: 'param_payment',
	PARAM_INTERIOR: 'param_interior',
	PARAM_SPACE: 'param_space',
	PARAM_EQUIPMENT: 'param_equipment',
	PARAM_FURNITURE: 'param_furniture',
	PARAM_TECH_UQUIPMENT: 'param_tech_equipment',
	PARAM_STAFF: 'param_staff',
	LOFT_TYPE: 'loft_type',
	FEATURE: 'feature',
	RULE: 'rule',
	FOOD: 'food',
	EQUIPMENT: 'equipment',
	DISTRICT: 'district_id',
	COMMON: 'p',
};

export const FILTER_CAPTIONS = {
	[FILTER_CODES.SPEC]: t('Тип мероприятия'),
	[FILTER_CODES.PRICE]: t('Стоимость аренды'),
	[FILTER_CODES.PARAM_MAIN]: t('Основные параметры'),
	[FILTER_CODES.PARAM_PAYMENT]: t('Параметры платежей'),
	[FILTER_CODES.PARAM_INTERIOR]: t('Интерьер'),
	[FILTER_CODES.PARAM_SPACE]: t('Дополнительные пространства'),
	[FILTER_CODES.PARAM_EQUIPMENT]: t('Оснащение'),
	[FILTER_CODES.PARAM_FURNITURE]: t('Мебель'),
	[FILTER_CODES.PARAM_TECH_UQUIPMENT]: t('Техническое оснащение'),
	[FILTER_CODES.PARAM_STAFF]: t('Персонал'),
	[FILTER_CODES.CAPACITY]: t('Тип мероприятия'),
	[FILTER_CODES.LOFT_TYPE]: t('Тип пространства'),
	[FILTER_CODES.FEATURE]: t('Особенности'),
	[FILTER_CODES.RULE]: t('Условия и правила'),
	[FILTER_CODES.FOOD]: t('Еда и напитки'),
	[FILTER_CODES.EQUIPMENT]: t('Оснащение'),
	[FILTER_CODES.DISTRICT]: t('Расположение'),
};

export const CAPACITY = [
	{ label: '1-5', value: '1-5' },
	{ label: '6-10', value: '6-10' },
	{ label: '11-15', value: '11-15' },
	{ label: '16-20', value: '16-20' },
	{ label: '21-25', value: '21-25' },
	{ label: '26-30', value: '26-30' },
	{ label: '31-40', value: '31-40' },
	{ label: '41-50', value: '41-50' },
	{ label: '51-70', value: '51-70' },
	{ label: '71-100', value: '71-100' },
	{ label: '101-150', value: '101-150' },
	{ label: '151-200', value: '151-200' },
	{ label: '201 и более', value: '201-9999' },
];

export const CITIES_WHITELIST = {
	5269: 'Казань',
	4400: 'Москва',
	4962: 'Санкт-Петербург',
	4917: 'Самара',
};
