// Кулинарный вечер /img/events/ill-event-culinary.svg

export const SUGGESTION_IMGS = [
	{
		key: 1,
		src: '/img/events/ill-event-wedding.svg',
	},
	{
		key: 17,
		src: '/img/events/ill-event-new-year.svg',
	},
	{
		key: 9,
		src: '/img/events/ill-event-birthday.svg',
	},
	{
		key: 11,
		src: '/img/events/ill-event-graduation.svg',
	},
	{
		key: 12,
		src: '/img/events/ill-event-children.svg',
	},
	{
		key: 14,
		src: '/img/events/ill-event-buffet.svg',
	},
	{
		key: 15,
		src: '/img/events/ill-event-corporate.svg',
	},
	{
		key: 16,
		src: '/img/events/ill-event-conference.svg',
	},
	{
		key: 24,
		src: '/img/events/ill-event-videoconference.svg',
	},
	{
		key: 25,
		src: '/img/events/ill-event-exhibition.svg',
	},
	{
		key: 26,
		src: '/img/events/ill-event-business.svg',
	},
	{
		key: 27,
		src: '/img/events/ill-event-casting.svg',
	},
	{
		key: 28,
		src: '/img/events/ill-event-film.svg',
	},
	{
		key: 29,
		src: '/img/events/ill-event-concert.svg',
	},
	{
		key: 32,
		src: '/img/events/ill-event-fashion.svg',
	},
	{
		key: 33,
		src: '/img/events/ill-event-presentation.svg',
	},
	{
		key: 34,
		src: '/img/events/ill-event-press.svg',
	},
	{
		key: 36,
		src: '/img/events/ill-event-theatrical.svg',
	},
	{
		key: 38,
		src: '/img/events/ill-event-focus-group.svg',
	},
	{
		key: 48,
		src: '/img/events/ill-event-training.svg',
	},
	{
		key: 49,
		name: 'Вебинар / онлайн-трансляция',
		src: '',
	},
	{
		key: 50,
		name: 'Массовое собеседование',
		src: '',
	},
	{
		key: 51,
		name: 'Переговоры',
		src: '',
	},
	{
		key: 52,
		name: 'Занятия йогой',
		src: '',
	},
	{
		key: 53,
		name: 'Занятия спортом',
		src: '',
	},
	{
		key: 54,
		name: 'Просмотр трансляций',
		src: '',
	},
	{
		key: 55,
		src: '/img/events/ill-event-dancing.svg',
	},
	{
		key: 56,
		name: 'Квест',
		src: '',
	},
	{
		key: 57,
		name: 'Квиз',
		src: '',
	},
	{
		key: 58,
		name: 'Мафия',
		src: '',
	},
	{
		key: 59,
		name: 'Покер',
		src: '',
	},
	{
		key: 60,
		name: 'Курсы',
		src: '',
	},
	{
		key: 61,
		name: 'Лекция',
		src: '',
	},
	{
		key: 62,
		src: '/img/events/ill-event-training.svg',
	},
	{
		key: 63,
		name: 'Семинар',
		src: '',
	},
	{
		key: 64,
		src: '/img/events/ill-event-training.svg',
	},
	{
		key: 65,
		name: 'Видеосъёмка',
		src: '',
	},
	{
		key: 66,
		name: 'Съёмка влога',
		src: '',
	},
	{
		key: 67,
		name: 'Съёмка кино',
		src: '',
	},
	{
		key: 68,
		name: 'Фотосъемка (Фотосессия)',
		src: '',
	},
	{
		key: 69,
		src: '/img/events/ill-event-festive.svg',
	},
	{
		key: 70,
		name: 'Вечеринка',
		src: '',
	},
	{
		key: 71,
		name: 'Встреча выпускников',
		src: '',
	},
	{
		key: 72,
		src: '/img/events/ill-event-bachelor.svg',
	},
	{
		key: 74,
		name: 'Дискотека',
		src: '',
	},
	{
		key: 75,
		src: '/img/events/ill-event-bachelor.svg',
	},
	{
		key: 76,
		name: 'Пижамная вечеринка',
		src: '',
	},
	{
		key: 77,
		name: 'Поминки',
		src: '',
	},
	{
		key: 78,
		name: 'Выступление',
		src: '',
	},
	{
		key: 80,
		name: 'Репетиция',
		src: '',
	},
	{
		key: 81,
		name: 'Спектакль',
		src: '',
	},
	{
		key: 82,
		src: '/img/events/ill-event-dancing.svg',
	},
	{
		key: 83,
		name: 'Быстрые свидания',
		src: '',
	},
	{
		key: 84,
		name: 'Встреча с друзьями',
		src: '',
	},
	{
		key: 85,
		name: 'Свидание',
		src: '',
	},
];

export const SUGGESTION_PNG_IMGS = [
	{
		key: 1,
		src: '/img/tall_events/wedding.png',
		code: 'wedding',
	},
	{
		key: 17,
		src: '/img/tall_events/new_year.png',
		code: 'new-year',
	},
	{
		key: 9,
		src: '/img/tall_events/birthday.png',
		code: 'birthday',
	},
	{
		key: 11,
		src: '/img/tall_events/graduation.png',
		code: 'graduation',
	},
	{
		key: 12,
		src: '/img/tall_events/children.png',
		code: 'children',
	},
	{
		key: 14,
		src: '/img/tall_events/buffet.png',
		code: 'buffet',
	},
	{
		key: 15,
		src: '/img/tall_events/corporate.png',
		code: 'corporate',
	},
	{
		key: 16,
		src: '/img/tall_events/conference.png',
		code: 'conference',
	},
	{
		key: 24,
		src: '/img/tall_events/videoconference.png',
		code: 'videoconference',
	},
	{
		key: 25,
		src: '/img/tall_events/exhibition.png',
		code: 'exhibition',
	},
	{
		key: 26,
		src: '/img/tall_events/business.png',
		code: 'business',
	},
	{
		key: 27,
		src: '/img/tall_events/casting.png',
		code: 'casting',
	},
	{
		key: 28,
		src: '/img/tall_events/film.png',
		code: 'film',
	},
	{
		key: 29,
		src: '/img/tall_events/concert.png',
		code: 'concert',
	},
	{
		key: 32,
		src: '/img/tall_events/fashion.png',
		code: 'fashion',
	},
	{
		key: 33,
		src: '/img/tall_events/presentation.png',
		code: 'presentation',
	},
	{
		key: 34,
		src: '/img/tall_events/press.png',
		code: 'press',
	},
	{
		key: 36,
		src: '/img/tall_events/theatrical.png',
		code: 'theatrical',
	},
	{
		key: 38,
		src: '/img/tall_events/focus_group.png',
		code: 'focus_group',
	},
	{
		key: 48,
		src: '/img/tall_events/training.png',
		code: 'training',
	},
	{
		key: 49,
		name: 'Вебинар / онлайн-трансляция',
		src: '/img/tall_events/videoconference.png',
		code: 'videoconference',
	},
	{
		key: 50,
		name: 'Массовое собеседование',
		src: '/img/tall_events/mass.png',
		code: 'mass',
	},
	{
		key: 51,
		name: 'Переговоры',
		src: '/img/tall_events/negotiations.png',
		code: 'negotiations',
	},
	{
		key: 52,
		name: 'Занятия йогой',
		src: '/img/tall_events/yoga.png',
		code: 'yoga',
	},
	{
		key: 53,
		name: 'Занятия спортом',
		src: '/img/tall_events/sport.png',
		code: 'sport',
	},
	{
		key: 54,
		name: 'Просмотр трансляций',
		src: '/img/tall_events/translation.png',
		code: 'translation',
	},
	{
		key: 55,
		src: '/img/tall_events/dancing.png',
		code: 'dancing',
	},
	{
		key: 56,
		name: 'Квест',
		src: '/img/tall_events/quest.png',
		code: 'quest',
	},
	{
		key: 57,
		name: 'Квиз',
		src: '/img/tall_events/queez.png',
		code: 'queez',
	},
	{
		key: 58,
		name: 'Мафия',
		src: '/img/tall_events/mafia.png',
		code: 'mafia',
	},
	{
		key: 59,
		name: 'Покер',
		src: '/img/tall_events/poker.png',
		code: 'poker',
	},
	{
		key: 60,
		name: 'Курсы',
		src: '/img/tall_events/courses.png',
		code: 'courses',
	},
	{
		key: 61,
		name: 'Лекция',
		src: '/img/tall_events/lections.png',
		code: 'lections',
	},
	{
		key: 62,
		src: '/img/tall_events/training.png',
		code: 'training',
	},
	{
		key: 63,
		name: 'Семинар',
		src: '/img/tall_events/seminar.png',
		code: 'seminar',
	},
	{
		key: 64,
		src: '/img/tall_events/training.png',
		code: 'training',
	},
	{
		key: 65,
		name: 'Видеосъёмка',
		src: '/img/tall_events/videoclip.png',
		code: 'videoclip',
	},
	{
		key: 66,
		name: 'Съёмка влога',
		src: '/img/tall_events/vlog.png',
		code: 'vlog',
	},
	{
		key: 67,
		name: 'Съёмка кино',
		src: '/img/tall_events/director.png',
		code: 'director',
	},
	{
		key: 68,
		name: 'Фотосъемка (Фотосессия)',
		src: '/img/tall_events/photosession.png',
		code: 'photosession',
	},
	{
		key: 69,
		src: '/img/tall_events/festive.png',
		code: 'festive',
	},
	{
		key: 70,
		name: 'Вечеринка',
		src: '/img/tall_events/party.png',
		code: 'party',
	},
	{
		key: 71,
		name: 'Встреча выпускников',
		src: '/img/tall_events/classmates.png',
		code: 'classmates',
	},
	{
		key: 72,
		src: '/img/tall_events/girls_night.png',
		code: 'girls_night',
	},
	{
		key: 74,
		name: 'Дискотека',
		src: '/img/tall_events/dance.png',
		code: 'dance',
	},
	{
		key: 75,
		src: '/img/tall_events/stag_party.png',
		code: 'stag_party',
	},
	{
		key: 76,
		name: 'Пижамная вечеринка',
		src: '/img/tall_events/sleep.png',
		code: 'sleep',
	},
	{
		key: 77,
		name: 'Поминки',
		src: '',
	},
	{
		key: 78,
		name: 'Выступление',
		src: '/img/tall_events/solo.png',
		code: 'solo',
	},
	{
		key: 80,
		name: 'Репетиция',
		src: '/img/tall_events/repetition.png',
		code: 'repetition',
	},
	{
		key: 81,
		name: 'Спектакль',
		src: '/img/tall_events/play.png',
		code: 'play',
	},
	{
		key: 82,
		src: '/img/tall_events/dancing.png',
		code: 'dancing',
	},
	{
		key: 83,
		name: 'Быстрые свидания',
		src: '/img/tall_events/fast-dating.png',
		code: 'fast-dating',
	},
	{
		key: 84,
		name: 'Встреча с друзьями',
		src: '/img/tall_events/friends.png',
		code: 'friends',
	},
	{
		key: 85,
		name: 'Свидание',
		src: '/img/tall_events/date.png',
		code: 'date',
	},
];
