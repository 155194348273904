import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';
import { ACTIONS_PREFIX } from '../constants';

const loadSuggestions = createAsyncThunk(
	`${ACTIONS_PREFIX}/loadSuggestions`,
	async (dbKey) => {
		const data = localStorage.getItem(dbKey);
		const { items } = JSON.parse(data || {});
		return items || [];
	},
);

const reducerFactory = (builder) => {
	builder.addCase(loadSuggestions.pending, (state, { meta }) => {
		const dbKey = meta.arg;

		return {
			...state,
			formerSuggestions: {
				...state.formerSuggestions,
				[dbKey]: {
					...state.formerSuggestions[dbKey],
					status: ASYNC_STATUSES.PENDING,
				},
			},
		};
	});

	builder.addCase(loadSuggestions.fulfilled, (state, action) => {
		const { payload, meta } = action;
		const dbKey = meta.arg;

		return {
			...state,
			formerSuggestions: {
				...state.formerSuggestions,
				[dbKey]: {
					data: payload,
					status: ASYNC_STATUSES.RESOLVED,
					error: null,
				},
			},
		};
	});

	builder.addCase(loadSuggestions.rejected, (state, action) => {
		const { error, meta } = action;
		const dbKey = meta.arg;

		return {
			...state,
			formerSuggestions: {
				...state.formerSuggestions,
				[dbKey]: {
					data: null,
					status: ASYNC_STATUSES.REJECTED,
					error: error.message,
				},
			},
		};
	});
};

export default {
	reducerFactory,
	action: loadSuggestions,
};
