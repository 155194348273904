import { CONTACTS_WRAP_ID } from '@src/containers/Session/constants';

export const checkStickyContactsHeight = () => {
	const el = document.getElementById(CONTACTS_WRAP_ID);
	if (el) {
		const height = el.offsetHeight + 20;

		document.documentElement.style.setProperty(
			'--sticky-contacts-height',
			height ? `${height}px` : 0,
		);
	}
};
