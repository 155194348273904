import { createSlice } from '@reduxjs/toolkit';
import { ACTIONS_PREFIX } from '../constants';

export const pureState = {
	images: {
		entries: {},
	},
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
		cacheImageEntry: (state, action) => {
			const { data } = action.payload;
			return {
				...state,
				images: {
					...state.images,
					entries: {
						...state.images.entries,
						[data.id]: data,
					},
				},
			};
		},
	},
});

export const actions = {
	...set.actions,
};

export const { reducer } = set;
