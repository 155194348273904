import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';
import getFetchTitleParams from '../selectors/getFetchTitlesParams';

const pureState = {
	fetchTitles: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const fetchTitles = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchTitles`,
	async (options = {}, { extra, getState }) => {
		const { type } = options;
		const { api } = extra;

		const params = getFetchTitleParams(getState());

		const strapiParams = {};

		switch (type) {
			case 'homepage':
				strapiParams.type = 'homepage';
				break;
			case 'profile_space':
				strapiParams.type = 'profile_space';
				strapiParams.city_gorko_id = params.cityId;
				break;
			case 'profile_place':
				strapiParams.type = 'profile_place';
				strapiParams.city_gorko_id = params.cityId;
				break;
			default:
				strapiParams.type = 'listing_space';
				strapiParams.city_gorko_id = params.cityId;
				if (params.queryString) {
					strapiParams.path_params = params.queryString;
				}
		}

		const strapiRes = await api.strapi.get(`site-config/seo-tags`, {
			params: strapiParams,
		});

		const t = strapiRes.data?.tags || {};

		return {
			entity: {
				titles: {
					title: t.title || '',
					description: t.description || '',
					h1: t.h1 || '',
					h1Text: t.h1_text || '',
					keyword: '',
					text: t.footer_text || '',
					template: t.template || false,
				},
			},
		};
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchTitles.pending, (state) => {
		return {
			...state,
			fetchTitles: {
				...state.fetchTitles,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(fetchTitles.fulfilled, (state, action) => {
		return {
			...state,
			fetchTitles: {
				...state.fetchTitles,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(fetchTitles.rejected, (state, action) => {
		const { meta } = action;
		return {
			...state,
			fetchTitles: {
				...state.fetchTitles,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { message: action.error.message, arg: meta.arg || null },
			},
		};
	});
};

export default {
	pureState,
	reducerFactory,
	action: fetchTitles,
};
