import qs from 'qs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';

const pureState = {
	loadSpaces: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};

const appendNonFitCard = (params, api) => async (data) => {
	const itemsList = data?.list || [];
	const query = params.list.filters;
	const parsedQuery = query && qs.parse(query);
	const firstId = parsedQuery?.first_id;
	const { page = 1 } = params.list;

	if (page === 1 && firstId && !itemsList.includes(firstId)) {
		const response = await api.apiv3.get(`loftcard/${firstId}`, { params });
		data.list.unshift(firstId);
		// eslint-disable-next-line no-param-reassign
		data.entity[firstId] = {
			...response.data.entity[firstId],
			isNonFit: true,
		};
	}
	return data;
};

const loadSpaces = createAsyncThunk(
	`${ACTIONS_PREFIX}/loadSpaces`,
	async ({ params, isAppendNonFit = true }, { extra }) => {
		const { api } = extra;
		const response = await api.apiv3.get('loftcard', { params });
		if (isAppendNonFit) {
			return appendNonFitCard(params, api)(response.data);
		}
		return response.data;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(loadSpaces.pending, (state) => {
		return {
			...state,
			loadSpaces: {
				...state.loadSpaces,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(loadSpaces.fulfilled, (state, action) => {
		return {
			...state,
			loadSpaces: {
				...state.loadSpaces,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(loadSpaces.rejected, (state, action) => {
		const { meta } = action;

		const newState = {
			...state,
			loadSpaces: {
				...state.loadSpaces,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: {
					message: action.error?.message || null,
					arg: meta.arg,
				},
			},
		};

		return newState;
	});
};

export default {
	pureState,
	reducerFactory,
	action: loadSpaces,
};
