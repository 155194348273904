export const addTimingHeader = (ctx, name, duration, desc) => {
	if (ctx?.res) {
		const curr = ctx.res.getHeader('Server-Timing');
		ctx.res.setHeader(
			'Server-Timing',
			`${curr ? `${curr},` : ''}${name};${
				desc ? `desc="${desc}";` : ''
			}dur=${duration}`,
		);
	}
};
