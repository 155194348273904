import { createSelector } from 'reselect';
import getRouterCtx from '@src/containers/Session/selectors/getRouterCtx';
import getFilterKeys from '@src/containers/Filters/selectors/getFilterKeys';
import { getSFUValues } from './getSFUValues';

const getFiltersQuery = createSelector(
	getRouterCtx,
	getFilterKeys,
	getSFUValues,
	(router, keys, sfu) => {
		const { query } = router;
		const result = {};

		keys.forEach((key) => {
			if (query[key] || sfu[key]) {
				let str = sfu[key] || query[key];
				switch (key) {
					case 'space_id':
						result.first_id = query[key];
						break;
					default:
						if (typeof str === 'string') {
							str = str.split(',').sort().join(',');
						}
						result[key] = str;
				}
			}
		});

		return result;
	},
);

export default getFiltersQuery;
