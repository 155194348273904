import { createSlice } from '@reduxjs/toolkit';
import { ACTIONS_PREFIX } from '../constants';

export const pureState = {
	imgs: [],
	activeIndex: 0,
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
		setActiveIndex: (state, action) => {
			const { activeIndex } = action.payload;
			return {
				...state,
				activeIndex,
			};
		},
		setImgs: (state, action) => {
			const { imgs } = action.payload;
			return {
				...state,
				imgs,
			};
		},
	},
});

export const actions = {
	...set.actions,
};

export const { reducer } = set;
