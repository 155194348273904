import { getCity } from '@src/containers/Session/selectors/getCity';
import { createSelector } from 'reselect';

export const loadSFUParams = createSelector(getCity, (city) => {
	const { id: cityId } = city;
	return {
		city_id: cityId || null,
		type: 'Restaurants',
		type_id: 3,
	};
});
