import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTIONS_PREFIX } from '../constants';

const initialState = {
	fetchSpacesPoints: {
		loading: false,
		error: null,
		data: null,
	},
};

const loadPoints = async (params, api) => {
	const result = await api.apiv3.get('loftcard', { params });
	return result.data;
};

const fetchSpacesPoints = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchSpacesPoints`,
	async (params, { extra }) => {
		const { api } = extra;
		const [result] = await Promise.all([loadPoints(params, api)]);
		return result;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchSpacesPoints.pending, (state, action) => {
		return {
			...state,
			fetchSpacesPoints: {
				...state.fetchSpacesPoints,
				loading: true,
				error: null,
				prevParams: action.meta.arg,
			},
		};
	});

	builder.addCase(fetchSpacesPoints.fulfilled, (state, action) => {
		return {
			...state,
			fetchSpacesPoints: {
				...state.fetchSpacesPoints,
				loading: false,
				error: null,
				data: action.payload,
			},
		};
	});
};

export default {
	action: fetchSpacesPoints,
	initialState,
	reducerFactory,
};
