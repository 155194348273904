export const ACTIONS_PREFIX = 'SpaceProfile';

export const RATE_LABELS = {
	NO_LABEL: 'Нет оценок',
	'0-4': 'Плохо',
	'4-7': 'Хорошо',
	'7-11': 'Отлично',
};

export const RATE_MODS = {
	'0-4': 'bad',
	'4-7': 'medium',
	'7-11': 'good',
};

export const PLACE_PROPS = [
	'param_square',
	'param_seats',
	'param_ceiling_height',
	'param_minimum_rental_duration',
];

export const EQUIP_GROUPS = [
	{
		icon: { src: '/img/params/option_furniture.svg' },
		title: t('Мебель'),
		codes: [
			'loft_furniture_1',
			'loft_furniture_2',
			'loft_furniture_3',
			'loft_furniture_4',
		],
	},
	{
		icon: { src: '/img/params/option_lighting.svg' },
		title: t('Световое оборудование'),
		codes: ['loft_light_equipment'],
	},
	{
		icon: { src: '/img/params/option_musical.svg' },
		title: t('Музыкальное оборудование'),
		codes: ['loft_music_equipment'],
	},
	{
		icon: { src: '/img/params/option_climatic.svg' },
		title: t('Климатическая техника'),
		codes: ['loft_climate_equipment'],
	},
	{
		icon: { src: '/img/params/option_multimedia.svg' },
		title: t('Мультимедийное оборудование'),
		codes: ['loft_multimedia_equipment'],
	},
	{
		icon: { src: '/img/params/option_photo.svg' },
		title: t('Оборудование для фото и видеосъёмки'),
		codes: ['loft_photo_equipment'],
	},
	{
		icon: { src: '/img/params/option_appliances.svg' },
		title: t('Бытовая и кухонная техника'),
		codes: ['loft_kitchen_equipment'],
	},
	{
		icon: { src: '/img/params/option_equipment.svg' },
		title: t('Оснащение'),
		codes: ['loft_equipment_1'],
	},
	{
		icon: { src: '/img/params/option_accessories.svg' },
		title: t('Аксессуары'),
		codes: ['loft_equipment_2'],
	},
	{
		icon: { src: '/img/params/option_games.svg' },
		title: t('Игры'),
		codes: ['loft_games'],
	},
];

export const SPACE_DESCRIPTION_ATTRIBUTE = 'param_description';
