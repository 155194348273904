import { createAsyncThunk } from '@reduxjs/toolkit';
import ASYNC_STATUSES from '@src/store/asyncStatuses';

import { ACTIONS_PREFIX } from '../constants';
import { loadStrapiSFUParams } from '../selectors/fetchStrapiSFUParams';

const pureState = {
	fetchStrapiSFU: {
		data: null,
		error: null,
		status: ASYNC_STATUSES.IDLE,
	},
};
const fetchStrapiSFU = createAsyncThunk(
	`${ACTIONS_PREFIX}/fetchStrapiSFU`,
	async (_, { extra, getState }) => {
		const { api } = extra;
		const loadParams = loadStrapiSFUParams(getState());

		const response = await api.strapi.get('sfus/seo-pack', {
			params: loadParams,
		});

		return response.data;
	},
);

const reducerFactory = (builder) => {
	builder.addCase(fetchStrapiSFU.pending, (state) => {
		return {
			...state,
			fetchStrapiSFU: {
				...state.fetchStrapiSFU,
				status: ASYNC_STATUSES.PENDING,
			},
		};
	});

	builder.addCase(fetchStrapiSFU.fulfilled, (state, action) => {
		return {
			...state,
			fetchStrapiSFU: {
				...state.fetchStrapiSFU,
				status: ASYNC_STATUSES.RESOLVED,
				data: action.payload,
				error: null,
			},
		};
	});

	builder.addCase(fetchStrapiSFU.rejected, (state, action) => {
		const { meta, error } = action;
		return {
			...state,
			fetchStrapiSFU: {
				...state.fetchStrapiSFU,
				data: null,
				status: ASYNC_STATUSES.REJECTED,
				error: { message: error.message || '', arg: meta.arg || {} },
			},
		};
	});
};

export default {
	action: fetchStrapiSFU,
	pureState,
	reducerFactory,
};
