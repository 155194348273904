import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { checkStickyContactsHeight } from '@src/components/Popup/helpers';
import { actions as sessionActions } from '../reducers';
import setSelectedCity from '../reducers/setSelectedCity';

export const useActions = () => {
	const dispatch = useDispatch();

	const resetState = useCallback(() => {
		dispatch(sessionActions.reset());
	}, [dispatch]);

	const toggleModal = useCallback(
		(params) => {
			checkStickyContactsHeight();
			dispatch(sessionActions.toggleModal(params));
		},
		[dispatch],
	);

	const setCityFromUrl = useCallback(
		async (params) => {
			await dispatch(
				setSelectedCity.action({ ...params, city: params.cityData }),
			);
			return dispatch(sessionActions.setCityFromUrl(params));
		},
		[dispatch],
	);

	const setCitySelected = useCallback(
		(params) => {
			return dispatch(setSelectedCity.action(params));
		},
		[dispatch],
	);

	const setGlobalLoading = useCallback(
		(params) => {
			dispatch(sessionActions.setGlobalLoading(params));
		},
		[dispatch],
	);

	const closeAllModalsWithAnimation = useCallback(() => {
		dispatch(sessionActions.closeAllModalsWithAnimation());
	}, [dispatch]);

	const saveSuggestion = useCallback(
		(dbKey) => (suggestion) => {
			dispatch(sessionActions.saveSuggestion({ dbKey, suggestion }));
		},
		[dispatch],
	);

	const saveClaimForm = useCallback(
		(values) => {
			dispatch(sessionActions.saveClaimForm({ values }));
		},
		[dispatch],
	);

	return {
		resetState,
		saveClaimForm,
		toggleModal,
		setCityFromUrl,
		setSelectedCity: setCitySelected,
		setGlobalLoading,
		closeAllModalsWithAnimation,
		saveSuggestion,
	};
};

export default useActions;
