export const ACTIONS_PREFIX = 'SpacesListing';

export const ORDER_VARIANTS = {
	// POPULAR: 'popular',
	// RATING: '-id',
	PRICE: '-price',
	PRICE_REVERSE: 'price',
	CAPACITY: 'capacity',
	CAPACITY_REVERSE: '-capacity',
};

export const ORDER_LABELS = {
	[ORDER_VARIANTS.POPULAR]: t('Сначала популярные'),
	[ORDER_VARIANTS.RATING]: t('Сначала новые'),
	[ORDER_VARIANTS.PRICE]: t('Сначала дороже'),
	[ORDER_VARIANTS.PRICE_REVERSE]: t('Сначала дешевле'),
	[ORDER_VARIANTS.CAPACITY]: t('Сначала вместительные'),
	[ORDER_VARIANTS.CAPACITY_REVERSE]: t('Сначала небольшие'),
};
