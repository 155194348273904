import React from 'react';
import { useDispatch } from 'react-redux';
import runClient from '../reducers/runClient';

const useClientSession = () => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		const res = dispatch(runClient.action());
		return () => {
			res.abort();
		};
	}, [dispatch]);
};

export default useClientSession;
