import { createSelector } from 'reselect';

const getPureKeys = (store) => store.Filters.loadFilters?.data?.list || [];

const getFilterKeys = createSelector(getPureKeys, (pureKeys) => {
	return ['district_id', 'p', 'place_id', 'space_id']
		.concat(pureKeys)
		.map((k) => {
			const [a, b] = k.split('__');
			return b || a;
		});
});

export default getFilterKeys;
