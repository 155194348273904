import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { ACTIONS_PREFIX } from '../constants';
import { getCity } from '../selectors/getCity';
import getCityFromUrl from '../selectors/getCityFromUrl';
import getCookieParams from '../selectors/getCookieParams';
import setSelectedCity from './setSelectedCity';
import saveClaimForm from './saveClaimForm';

const ssrSession = createAsyncThunk(
	`${ACTIONS_PREFIX}/runClient`,
	async (_, thunkAPI) => {
		const { dispatch, getState } = thunkAPI;
		const city = getCity(getState());
		const urlCity = getCityFromUrl(getState());

		const cookieParams = getCookieParams(getState());
		const cityFromCookies = Cookies.get('userCity', {
			...cookieParams,
		});
		const claimDataFromCookie = Cookies.get('claimFormData', {
			...cookieParams,
		});

		if (!cityFromCookies || !!urlCity) {
			await dispatch(
				setSelectedCity.action({ city: urlCity || city || null }),
			);
		}

		if (claimDataFromCookie) {
			try {
				const values = JSON.parse(claimDataFromCookie);
				await dispatch(saveClaimForm.action({ values }));
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('claimDataFromCookie', err);
			}
		}
	},
);

export default {
	action: ssrSession,
};
