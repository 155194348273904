import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ACTIONS_PREFIX } from '../constants';
import fetchFavourSpaces from './fetchFavourSpaces';

export const pureState = {};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			const { SpacesFavourite } = action.payload;
			return {
				...state,
				fetchFavourSpaces: SpacesFavourite.fetchFavourSpaces,
			};
		});
		fetchFavourSpaces.reducerFactory(builder);
	},
});

export const actions = {
	...set.actions,
	fetchFavourSpaces: fetchFavourSpaces.action,
};

export const { reducer } = set;
