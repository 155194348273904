import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ACTIONS_PREFIX } from '../constants';
import fetchPlaceProfile from './fetchPlaceProfile';
import loadStrapiProfile from './loadStrapiProfile';

export const pureState = {
	...fetchPlaceProfile.pureState,
	...loadStrapiProfile.pureState,
	localPager: {
		perPage: 5,
	},
};

const set = createSlice({
	initialState: pureState,
	name: ACTIONS_PREFIX,
	reducers: {
		reset: () => pureState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			return {
				...state,
				fetchPlaceProfile:
					action.payload.PlaceProfile.fetchPlaceProfile,
				loadStrapiProfile:
					action.payload.PlaceProfile.loadStrapiProfile,
			};
		});

		fetchPlaceProfile.reducerFactory(builder);
		loadStrapiProfile.reducerFactory(builder);
	},
});

export const actions = {
	...set.actions,
	fetchPlaceProfile: fetchPlaceProfile.action,
};

export const { reducer } = set;
